@import '../../styles/utils.scss';

.el {
  width: 100%;
  height: 52px;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  bottom: 104px;
  margin-top: -26px;
  margin-bottom: -26px;
  pointer-events: none;
  @include tablet {
    bottom: 76px;
  }
  .inner {
    pointer-events: auto;
    display: block;
    position: absolute;
    z-index: 10;
    width: 52px;
    height: 52px;
    line-height: 52px;
    background-color: rgba(247,247,247,0.9);
    border-radius: 50%;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
    top: 0;
    // bottom: 78px;
    right: 82px;
    transform-origin: center;
    transform: translateX(300px);
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    opacity: 0;
    span {
      display: block;
      margin-top: 3px;
    }
    @include tablet {
      // bottom: 50px;
      right: 20px;
    }
    &.show {
      transform: translateX(0);
      opacity: 1;
    }
  }
  &.sticky {
    position: relative;
    bottom: 0;
    .inner {
      // bottom: -52px;
      @include tablet {
        // bottom: -52px;
        right: 20px;
      }
    }
  }
}
